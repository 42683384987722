<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Sales Incentive Market Practices Report
      </h2>
      <img
        src="../../assets/img/tools_Sales_Incentive_Market_Practices_Report.jpg"
        alt="Executive Compensation Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >
        <p class="py-4">
          Getting the salesforce hungry for more sales is the aim of every company.
          A good sales incentive plan is a win-win financially for the company and the salesforce.
        </p>
        <br>
        <p class="py-4">
            This survey report provides an update of the latest sales
            commission/bonus/incentive
            trends covering 15 APAC locations, and provides insights into
            country-specific practices.
        </p>
        <br>
        <p class="py-4">
        Overview of report:
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>General Items: <span style="color:black;">Target Cash Mix,
            Variable Pay Composition</span></li>
          <li>Sales Commission:
              <span style="color:black;">Target setting, 1st dollar rate,
                Threshold Rate, and Upside Rate,
                  Team vs. Individual, Pay-out Frequency</span></li>
          <li>Sales Bonus (aka Sales Incentive):
              <span style="color:black;">Target setting, Team vs. Individual Weightage,
                Pay-out Frequency</span></li>
          <li>Performance Measures</li>
          <li>Top Sales Award: <span style="color:black;">Eligibility, Incentive Amount</span></li>
          <li>Guarantee Commission in Difficult Times</li>
        </ul>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
